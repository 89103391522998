//TapahtumatSivun Pääkomponentti

import React, { useState, useEffect } from "react";
import TapahtumatMap from "./tapahtumatmap.component";
import AlaValikko from "../../components/alavalikko/alavalikko.component";
import axios from "axios";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import "./tapahtumatpage.style.css";

const TapahtumatPage = ({ map }) => {
  const [page, setPage] = useState(1);

  const realapi =
    "https://kt-cors.azurewebsites.net/tapahtumat.salo.fi/wp-json/wp/v2/posts";
  // const realapi = "https://tapahtumat.salo.fi/wp-json/wp/v2/posts";
  const [totalCount, setTotalCount] = useState(25);

  const [data, setData] = useState({ type: "FeatureCollection", features: [] });
  const [tapahtumaPopup, setTapahtumaPopup] = useState(false);

  const [currentPos, setCurrentPos] = useState([0, 0]);
  const [currentId, setCurrentId] = useState(null);

  const handleChange = (lat, lng) => {
    if (lat) {
      setCurrentPos([lat, lng]);
      console.log(currentId);
    }
  };

  // useEffect(() => {
  //   axios({
  //     method: "GET",
  //     url: realapi,
  //     params: {page: page, per_page: 20},
  //   })
  //   .then(response => setData([...data, ...response.data].sort((a,b) => {
  //   return Date.parse(a.acf.start_datetime) - Date.parse(b.acf.start_datetime);
  //   })))
  //   .catch(err => console.log(err))
  // }, [page])

  useEffect(() => {
    const jsons =
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
        ? process.env.PUBLIC_URL + "/dataa/testjsons/"
        : process.env.PUBLIC_URL + "/jsons/";
    fetch(jsons + "Tapahtumat.json")
      .then((response) => {
        if (response.ok) return response.json();
        else return { type: "FeatureCollection", features: [] };
      })
      .then((newjson) => {
        setData(newjson);
      });
  }, []);

  return (
    <>
      {/*Add kohde btn*/}
      <div className="tapahtuma_addbtn" onClick={() => setTapahtumaPopup(true)}>
        <AiOutlinePlus className="tapahtuma_addicon" size={25} />
      </div>

      {tapahtumaPopup ? (
        <div className="tapahtumapopup">
          <AiOutlineClose
            className="tapahtuma_closepopupbtn"
            onClick={() => setTapahtumaPopup(false)}
          />
          <h1 className="tapahtumapopupheader">Luo uusi tapahtuma!</h1>
          <p className="tapahtumapopuptext">
            Voit jakaa muille tapahtuman ja saada tapahtuma mobiilisovellukseen
            sekä Someron tapahtumakalenteriin
          </p>

          <p
            className="tapahtuma_luotilibtn"
            onClick={() => {
              window.open(
                `https://admin.taskussa.info/sign-in.php?kaupunki=${process.env.REACT_APP_CITY}`
              );
              setTapahtumaPopup(false);
            }}
          >
            Luo tili
          </p>
          <p
            className="tapahtuma_kirjaudubtn"
            onClick={() => {
              // getLocation();
              window.open(
                `https://admin.taskussa.info/tapahtumat/index.php?kaupunki=${process.env.REACT_APP_CITY}`
              );
              setTapahtumaPopup(false);
            }}
          >
            Kirjaudu
          </p>
        </div>
      ) : null}

      <div onClick={() => setTapahtumaPopup(false)}>
        <TapahtumatMap
          resetId={
            currentId ? () => setCurrentId(currentId) : () => setCurrentId(null)
          }
          currentId={currentId}
          latt={currentPos[0]}
          lngg={currentPos[1]}
          data={data}
          map={map}
        />
        <AlaValikko
          setPage={() => setPage(page + 1)}
          currentId={(id) => setCurrentId(id)}
          move={(lat, lng) => handleChange(lat, lng)}
          data={data.features}
        />
      </div>
    </>
  );
};

export default TapahtumatPage;
